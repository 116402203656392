<template>
  <div class='input-text'>
    <input
      type='text'
      v-model='emitText'
      :maxlength='maxLength'
    />
    <p v-if='errorMessage'>{{ errorMessage }}</p>
  </div>
</template>

<script>
export default {
  props: {
    defaultText: String,
    errorMessage: String,
    maxLength: Number,
  },
  data() {
    return {
      emitText: String,
    };
  },
  created() {
    this.emitText = this.defaultText;
  },
  updated() {
    this.$emit('emitInputText', this.emitText, this.name);
  },
};
</script>

<style lang="scss">
.input-text {
  input {
    background-color: $color-gray;
    border: none;
    border-radius: 3px;
    padding: 8px 10px;
    font-size: 14px;
    outline: none;
    width: 100%;
    max-width: 400px;
  }
  p {
    font-size: 12px;
    color: $color-red;
    margin-top: 2px;
  }
}
</style>
