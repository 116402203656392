<template>
  <div class='account'>
    <layout-header />
    <main class='account__main base-content-pc'>
      <section class='account__main__left'>
        <select-bar-setting />
      </section>
      <section class='account__main__right'>
        <div class='account__main__right__title'>
          <h3>アカウント</h3>
          <button-normal
            @emitClick='execUpdateUser()'
            :disabled='judgeDisabledUpdateButton'
            :loading='isLoading.updateUser'
          >
            <p>更新する</p>
          </button-normal>
        </div>
        <div class='account__main__right__username'>
          <h5>ユーザー名</h5>
          <input-text
            :defaultText='$store.state.user.profile.name'
            :errorMessage='errorMessage.name'
            :maxLength='rule.name.maxLength'
            @emitInputText='saveUploadText'
          />
        </div>
        <router-link class='account__main__right__delete' :to='{ name: "Withdrawal"}'>
          アカウントを削除される方はこちら
        </router-link>
      </section>
    </main>
    <layout-footer />
  </div>
</template>

<script>
import LayoutHeader from '@/components/layouts/LayoutHeader.vue';
import LayoutFooter from '@/components/layouts/LayoutFooter.vue';
import ButtonNormal from '@/components/atoms/button/ButtonNormal.vue';
import InputText from '@/components/atoms/input/InputText.vue';
import SelectBarSetting from '@/components/atoms/select/SelectBarSetting.vue';

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
    ButtonNormal,
    InputText,
    SelectBarSetting,
  },
  data() {
    return {
      isLoading: {
        updateUser: false,
      },
      updateInfo: {
        name: null,
      },
      errorMessage: {
        name: '',
      },
      rule: {
        name: {
          maxLength: 100,
        },
      },
    };
  },
  computed: {
    judgeDisabledUpdateButton() {
      // エラーメッセージ判定
      if (this.errorMessage.name !== '') return true;
      if (this.updateInfo.name === null) return true;
      return false;
    },
  },
  methods: {
    showNoticeBar(title, type, text) {
      this.$notify({
        title,
        text,
        type,
        speed: 400,
        group: 'top-right',
      });
    },
    saveUploadText(name) {
      this.updateInfo.name = name;
    },
    async execUpdateUser() {
      this.isLoading.updateUser = true;
      const updateInfo = {};
      if (this.updateInfo.name !== null) updateInfo.name = this.updateInfo.name;
      const result = await this.$store.dispatch('updateUser', updateInfo);

      this.isLoading.updateUser = await false;
      if (await result === 401) {
        this.$router.push({ name: 'Top' });
      } else if (await result) {
        // アップロード情報初期化
        this.updateInfo.name = null;

        this.showNoticeBar(
          'ユーザー情報の変更を完了しました',
          'success',
        );
      } else {
        this.showNoticeBar(
          'ユーザー情報の変更に失敗しました',
          'error',
          'しばらく時間を空けてから再度お試しください',
        );
      }
    },
  },
  watch: {
    // リアルタイムバリデーション
    'updateInfo.name': function updateInfoName(to) {
      if (to !== null) {
        if (to.length > this.rule.name.maxLength) {
          this.errorMessage.name = 'ユーザー名の最大文字数を超えています';
        } else if (to.length === 0) {
          this.errorMessage.name = 'ユーザー名を入力してください';
        } else {
          this.errorMessage.name = '';
        }
      }
    },
  },
};
</script>

<style lang="scss">
.account {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.account__main {
  margin: 0 auto 120px;
  display: flex;
  max-width: 100%;
}

.account__main__left {
  width: 25%;
  margin-right: 30px;
}

.account__main__right {
  width: 75%;
  background-color: $color-white;
  padding: 30px;
}

.account__main__right__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  h3 {
    font-size: 24px;
    font-weight: 600;
  }
}

.account__main__right__username,
.account__main__right__introduction {
  h5 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }
}

.account__main__right__username {
  margin-bottom: 5px;
}

.account__main__right__delete {
  font-size: 12px;
  margin-top: 15px;
  color: $color-dark-gray;
  text-decoration: none;
}

.account__main__right__delete:hover {
  text-decoration: underline;
}
</style>
