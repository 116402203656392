<template>
  <ul class='select-bar-setting'>
    <li
      v-for='(settingMenu ,index) in settingMenuList'
      :key='index'
    >
      <router-link
        :class='judgeFocus(settingMenu.routeName)'
        :to='{name: settingMenu.routeName, params: {ticketName: settingMenu.ticketName} }'
      >
        {{ settingMenu.title }}
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      settingMenuList: [
        {
          title: 'アカウント',
          routeName: 'SettingAccount',
        },
        {
          title: 'コース',
          routeName: 'SettingCourses',
        },
        // {
        //   title: 'チケット',
        //   routeName: 'SettingTickets',
        //   ticketName: 'question',
        // },
        // {
        //   title: 'クレジットカード管理',
        //   routeName: 'SettingCreditCard',
        // },
        {
          title: '通知',
          routeName: 'SettingNotification',
        },
      ],
      emailLoginMenuList: [
        {
          title: 'メールアドレス変更',
          routeName: 'SettingEmail',
        },
        {
          title: 'パスワード変更',
          routeName: 'SettingPassword',
        },
      ],
    };
  },
  created() {
    if (this.emailLogin) {
      this.addEmailLoginMenuList();
    }
  },
  computed: {
    emailLogin() {
      return this.$store.state.user.oauthPlatFormId.indexOf(4) !== -1;
    },
  },
  methods: {
    judgeFocus(routeName) {
      return {
        focus: this.$route.name === routeName,
      };
    },
    addEmailLoginMenuList() {
      this.settingMenuList = this.settingMenuList.concat(this.emailLoginMenuList);
    },
  },
};
</script>

<style lang="scss">
.select-bar-setting {
  width: 100%;
  background-color: $color-white;
  margin: 0;
  padding: 15px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  li {
    a {
      display: flex;
      text-align: left;
      text-decoration: none;
      color: $color-black;
      padding: 10px;
    }
  }
}

.focus {
  background-color: $color-gray;
  border-radius: 3px;
  font-weight: 600;
  cursor: default;
}
</style>
